import React from 'react';
import * as styles from './pageHeading.module.scss';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';

export interface PageHeadingProps {
  title: string;
  children?: React.ReactNode;
  color?: string;
  className?: string;
}

const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  children,
  color,
  className = '',
}) => (
  <Container>
    <Box className={[styles.Heading, className].join(' ')} style={{ color }}>
      <Box className={styles.HeadingColumn}>
        <h1>{title}</h1>
      </Box>
      {children && <Box className={styles.HeadingColumn}>{children}</Box>}
    </Box>
  </Container>
);

export default PageHeading;
