import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import Base from '@webLayouts/Base/Base';
import { PageFinanceQuery } from '@generated/GraphqlTypes';
import Finance from '@webPages/Finance/Finance';

const FinancePage: React.FC<PageProps<PageFinanceQuery>> = ({ data }) => {
  const title = data?.finance?.frontmatter?.title || '';
  const body = data?.finance?.html || '';

  const seoTitle = data?.finance?.frontmatter?.seoTitle || undefined;
  const seoDescription =
    data?.finance?.frontmatter?.seoDescription ||
    data?.finance?.excerpt ||
    undefined;

  const image = data?.finance?.frontmatter?.heroImage?.publicURL || undefined;
  const image2 = getImage(
    data?.finance?.frontmatter?.heroImage as ImageDataLike
  );
  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <Finance title={title} body={body} image={image2} />
    </Base>
  );
};

export default FinancePage;

export const query = graphql`
  query PageFinance {
    finance: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "finance" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription

        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
      }
    }
  }
`;
