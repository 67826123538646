import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import VisitOurDisplay from '@webOrganisms/VisitOurDisplay/VisitOurDisplay';

export default ({
  alignVideo = 'right',
}: {
  alignVideo?: 'left' | 'right';
}) => (
  <StaticQuery
    query={query}
    render={data => {
      const props = {
        alignVideo,
        title: `${data?.visitOurDisplay?.frontmatter?.title}`,
        body: `${data?.visitOurDisplay?.html}`,
        videoId: `${data?.visitOurDisplay?.frontmatter?.videoId}`,
        bookUrl: data?.visitOurDisplay?.frontmatter?.bookUrl,
        bookButtonLabel: data?.visitOurDisplay?.frontmatter?.bookButtonLabel,
      };
      return <VisitOurDisplay {...props} />;
    }}
  />
);

export const query = graphql`
  query SectionVisitOurDisplay {
    visitOurDisplay: markdownRemark(
      fields: { collection: { eq: "sections" } }
      frontmatter: { name: { eq: "visit-our-display" } }
    ) {
      frontmatter {
        title
        videoId
        bookUrl
        bookButtonLabel
      }
      html
    }
  }
`;
