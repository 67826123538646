import React from 'react';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Button from '@webAtoms/Button/Button';
import * as styles from './visitOurDisplay.module.scss';
import { openBookDisplayVisit } from '@helpers/calendly';

export interface VisitOurDisplayProps {
  title: string;
  videoId: string;
  body: string;
  bookUrl?: string;
  bookButtonLabel?: string;
  alignVideo: 'left' | 'right';
}

const VisitOurDisplay: React.FC<VisitOurDisplayProps> = ({
  title,
  videoId,
  body,
  bookUrl,
  bookButtonLabel,
  alignVideo,
}) => {
  const onClickBookADisplay = () => {
    openBookDisplayVisit(bookUrl || '');
  };
  return (
    <Box
      className={`${styles.visitOurDisplay} ${
        alignVideo === 'left' ? styles.left : ''
      }`}
    >
      <Container>
        <h2>{title}</h2>

        <Box className={styles.visitOurDisplayInner}>
          <Box className={styles.visitOurDisplayVideo}>
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&playsinline=1&controls=1&rel=0&loop=1&playlist=${videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Box className={styles.visitOurDisplayCaption}>
            <Box>
              <h2>{title}</h2>
              <Box htmlContent={body} paddingBottom={Spacing.S} />
              {bookUrl && (
                <Button
                  className={styles.visitOurDisplayButton}
                  intent="primary"
                  icon="arrowRight"
                  onClick={onClickBookADisplay}
                >
                  {bookButtonLabel || 'Book a display visit'}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default VisitOurDisplay;
