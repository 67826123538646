import React from 'react';
import Box from '@webAtoms/Box/Box';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';
import VisitOurDisplay from '@webSections/VisitOurDisplay';
import * as Route from '@routes';
import Container from '@webAtoms/Container/Container';
import BlogPost from '@webOrganisms/BlogPost/BlogPost';
import * as styles from './finance.module.scss';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export interface FinanceProps {
  title: string;
  body: string;
  image?: IGatsbyImageData;
}

const Finance: React.FC<FinanceProps> = ({ title, body, image }) => {
  return (
    <Box>
      <PageHeading title={title} />

      <Container>
        <Box className={styles.finance}>
          <Box className={styles.financeHero}>
            {image && (
              <GatsbyImage loading="lazy" image={image} alt="Three Steps" />
            )}
          </Box>
          <Box className={styles.financeContent}>
            <Box htmlContent={body} />
          </Box>
        </Box>
      </Container>

      <VisitOurDisplay />

      <UpNext
        links={[
          {
            label: 'FAQ',
            href: Route.faqs(),
          },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default Finance;
