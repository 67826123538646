import React from 'react';
import Container from '@webAtoms/Container/Container';
import Box from '@webAtoms/Box/Box';
import Text, { FontStyle } from '@webAtoms/Text/Text';
import Logo from '@webAtoms/Logo/Logo';
import Button from '@webAtoms/Button/Button';
import * as styles from './upNext.module.scss';

export interface UpNextProps {
  links: UpNextLink[];
}

export interface UpNextLink {
  label: string;
  href: string;
}

const UpNext: React.FC<UpNextProps> = ({ links }) => {
  return (
    <Box className={styles.upNext}>
      <Container className={styles.upNextContainer}>
        <Box className={styles.upNextLogo}>
          <a href="/">
            <Logo className={styles.upNextLogoSvg} />
          </a>
        </Box>
        <Box className={styles.upNextTitle}>
          <Text semantic="h3" fontStyle={FontStyle.Display6}>
            Up Next
          </Text>
        </Box>
        <Box className={styles.upNextInner}>
          <Box className={styles.upNextColumnLinks}>
            <Box>
              <Box>
                {links.map((link, i) => (
                  <Box key={i} className={styles.upNextLink}>
                    <Button
                      className={styles.upNextColumnLinksButton}
                      fluid
                      icon="arrowRightAlt"
                      href={link.href}
                    >
                      <Text fontStyle={FontStyle.BodyXLarge}>{link.label}</Text>
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box desktop>
            <a href="/" title="Ecoliv">
              <Logo />
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default UpNext;
